import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When Brant jabbed at his phone for the tenth time, Jolie finally replied. After a short conversation, Jolie had agreed to leave her house for what had seemed like the first time in many years. Brant suggested to meet at a park close to both their homes. He started to walk along the rough path that led him to a secluded, small park. It was around mid-September meaning all the colors were starting to change, shifting from bright green scenery to an orange hue. By the time Brant had arrived at their meeting point, it was around late afternoon. A chilly breeze flew by, causing a swirl of freshly fallen leaves to spin in a small spiral. His eyes were bright as he watched the entrancing display. Brant shivered from the cold and walked over to a nearby bench. He would later learn that he would have to sit and wait there for several hours.`}</p>
    <p>{`Jolie sat at her desk, staring at her computer. Her long unkempt hair hung over her face casting a large, dark shadow, similar to the ones under her eyes. Her eyes were glassy and her gaze was distant and hollow. These past weeks all Jolie did was write, and write. She wouldn't leave the house and hardly slept or ate leaving her malnourished and constantly exhausted. Even though she had managed to get another splitting headache, the third this day, she promised herself she wouldn't leave until she finished this one last chapter. The next thing she knew she had promised to finish a few more chapters and then several more. All her plans were immediately forgotten, and the thought of even going outside had completely slipped her mind.`}</p>
    <p>{`By the time the sun set Jolie still hadn't shown up, leaving Brant in the dark both figuratively and literally. She wouldn't answer his texts or his calls causing him to intensely worry. He started to become squirmish and at that moment he decided to abandon the bench and leave. He started to go in the direction of his home but a nagging thought at the back of his head made him think twice. Brant had tried to meet up with Jolie a few times before but she either cancelled or never showed. With this thought, he skidded to a stop and turned in the other direction.`}</p>
    <p>{`Before they had mostly gone their separate ways, Brant and Jolie used to be good friends which is why he knew exactly where she lived. He looked up at the crumbling apartment, the building was only a few years old but it looked as if it had been there forever. The apartment was weatherbeaten and its paint was starting to peel. Brant looked around to see that he was surrounded by many other tottering buildings that loomed over him. He stepped up the small steps to reach the tall dark wood door. He looked over the list of names next to the small, worn out buttons. He found the doorbell beside Jolie’s name and rang it. He rang it again, and again, and every time getting continual silence as an answer. Eventually, he chose to give up and rang the nearest doorbell. Brant finally received an answer from a raspy voice, and the door creaked open.`}</p>
    <p>{`Brant turned the handle and was surprised to find out that the door was not locked, so he stepped in and took in his surroundings. Everything was covered in a thick layer of dust, it looked as if no one had ever lived there. The apartment itself was quite small and a musty smell filled the whole room. There was not much light, as there was only one dim lamp. Then he saw her, she was hunched over and staring at the computer screen on her desk with a thousand papers surrounding her. The papers were either crumpled, marked with red or even ripped to shreds. He walked over to her looking into her sunken, glassy eyes and tried to shake her awake from her trance-like state. She looked dazed but didn't react in any other way so Brant kept trying to break her out of her haze, but nothing seemed to work.`}</p>
    <p>{`He started to panic and then looked over to her computer screen and was shocked to see what was written.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      